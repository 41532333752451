import Vue from 'vue'
import VueRouter from 'vue-router'
import bootstrap from '@/bootstrap.js'
import store from '@/store'
import i18n from '@/plugins/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'dashboard' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login'),
    props: (route) => ({
      redirect: route.query.redirect || { name: 'dashboard' }
    }),
    meta: {
      requiresAuth: false,
      requiresUnauth: true,
      layout: {
        fillHeight: true,
        hideNavigation: true
      },
      title: i18n.t('auth.login.title')
    }
  },
  {
    path: '/login-sso',
    name: 'login-sso',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/LoginSso'),
    props: (route) => ({
      redirect: route.query.redirect || { name: 'dashboard' },
      token: route.query.token
    })
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
      ),
    props: (route) => ({
      token: route.query.token
    }),
    meta: {
      requiresAuth: false,
      requiresUnauth: true,
      layout: {
        fillHeight: true,
        hideNavigation: true
      },
      title: i18n.t('auth.reset-password.title')
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "account" */ '@/views/Account'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.account'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/Z01TfV6e'
    }
  },
  {
    path: '/security',
    name: 'security',
    component: () =>
      import(/* webpackChunkName: "security" */ '@/views/Security'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.security'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/nBh0sckU'
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: () =>
      import(/* webpackChunkName: "clients" */ '@/views/Clients'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.clients')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      title: i18n.t('navigation.dashboard'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/YZPQxr1o'
    }
  },

  /**
   * Application
   */
  {
    path: '/application/candidates',
    name: 'candidates',
    component: () =>
      import(/* webpackChunkName: "candidates" */ '@/views/Candidates'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.candidates'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/X6pc80bx'
    }
  },
  {
    path: '/application/candidate/:id?',
    name: 'candidate',
    component: () =>
      import(/* webpackChunkName: "candidate" */ '@/views/Candidate'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('candidates.details.title'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/amNydADw'
    }
  },
  {
    path: '/application/customforms',
    name: 'customforms',
    component: () =>
      import(/* webpackChunkName: "customforms" */ '@/views/Customforms'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.customforms'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HCXW8xiP'
    }
  },
  {
    path: '/application/videoinfos',
    name: 'videoinfos',
    component: () =>
      import(/* webpackChunkName: "videoinfos" */ '@/views/Videoinfos'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.videoinfos'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/D7Tw1k7J'
    }
  },
  {
    path: '/application/joboffers',
    name: 'joboffers',
    component: () =>
      import(/* webpackChunkName: "joboffers" */ '@/views/Joboffers'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.joboffers'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/93yFq8Xv'
    }
  },
  {
    path: '/application/statistics',
    name: 'application-statistics',
    component: () =>
      import(
        /* webpackChunkName: "application-statistics" */ '@/views/ApplicationStatistics'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.application-statistics'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/zeTM1eGv'
    }
  },

  /**
   * Absences
   */
  {
    path: '/absences/calendar',
    name: 'absencecalendar',
    component: () =>
      import(/* webpackChunkName: "absences" */ '@/views/Absences'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.absencecalendar'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/otA0hmDu'
    }
  },
  {
    path: '/absences/requests',
    name: 'absencerequests',
    component: () =>
      import(
        /* webpackChunkName: "absencerequests" */ '@/views/Absencerequests'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      requiresPermissions: ['absencerequests'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.absencerequests'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/PD1u2ebw'
    }
  },
  {
    path: '/absencerequests/review',
    name: 'absencerequests-review',
    component: () =>
      import(
        /* webpackChunkName: "absencerequests" */ '@/views/AbsencerequestReview'
      ),
    props: (route) => ({ token: route.query.token }),
    meta: {
      requiresAuth: false,
      layout: {
        fillHeight: false
      },
      title: i18n.t('absencerequests.review.title'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/PD1u2ebw'
    }
  },

  /**
   * Timetracking
   */
  {
    path: '/timetracking/table',
    name: 'timetrackingtable',
    component: () =>
      import(/* webpackChunkName: "timetracking" */ '@/views/Timerecords'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.timetrackingtable'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/pQp0CPss'
    }
  },
  {
    path: '/timetracking/calendar',
    name: 'timetrackingcalendar',
    component: () =>
      import(
        /* webpackChunkName: "timetracking" */ '@/views/TimerecordsCalendar'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.timetrackingtable'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/Ryz0r470'
    }
  },
  {
    path: '/timetracking/reports',
    name: 'timetracking-reports',
    component: () =>
      import(
        /* webpackChunkName: "timetracking" */ '@/views/TimetrackingReports'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.timetracking-reports'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/ffyqc3Q7'
    }
  },
  {
    path: '/timetracking/reports/project-table',
    name: 'timetracking-reports-project-table',
    component: () =>
      import(
        /* webpackChunkName: "timetracking" */ '@/views/TimetrackingReportsProjectTable'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.timetracking-reports'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/ffyqc3Q7'
    }
  },
  {
    path: '/timetracking/reports/usage-by-user',
    name: 'timetracking-reports-usage-by-user',
    component: () =>
      import(
        /* webpackChunkName: "timetracking" */ '@/views/TimetrackingReportsUsageByUser'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.timetracking-reports'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/ffyqc3Q7'
    }
  },
  {
    path: '/shifts/scheduler',
    name: 'shiftscheduler',
    component: () =>
      import(/* webpackChunkName: "shiftscheduler" */ '@/views/Shiftscheduler'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER',
        'EMPLOYEE'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.shiftscheduler'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/7jPD5S9u'
    }
  },
  {
    path: '/shifts/reports',
    name: 'shifts-reports',
    component: () =>
      import(/* webpackChunkName: "shifts" */ '@/views/ShiftsReports'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.shifts-reports'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/9U0n11Pk'
    }
  },
  {
    path: '/shifts/reports/usage-by-user',
    name: 'shifts-reports-usage-by-user',
    component: () =>
      import(
        /* webpackChunkName: "shifts" */ '@/views/ShiftsReportsUsageByUser'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.shifts-reports'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/9U0n11Pk'
    }
  },

  /**
   * Administration
   */
  {
    path: '/administration',
    name: 'administration',
    component: () =>
      import(/* webpackChunkName: "administration" */ '@/views/Administration'),
    meta: {
      requiresAuth: true,
      requiresRoles: [
        'SUPER_ADMINISTRATOR',
        'ADMINISTRATOR',
        'STAFF',
        'TRAINER'
      ],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.administration'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/jKAgpSBv'
    }
  },
  {
    path: '/administration/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.users'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/RBmzratZ'
    }
  },
  {
    path: '/administration/groups',
    name: 'groups',
    component: () => import(/* webpackChunkName: "groups" */ '@/views/Groups'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.groups'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/o8uzhCtW'
    }
  },
  {
    path: '/administration/emails',
    name: 'administration-emails',
    component: () =>
      import(
        /* webpackChunkName: "administration-emails" */ '@/views/AdministrationEmails'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.emails'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/wmYKwKci'
    }
  },
  {
    path: '/administration/absences',
    name: 'administration-absences',
    component: () =>
      import(
        /* webpackChunkName: "administration-absences" */ '@/views/AdministrationAbsences'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.absences'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/mYqFwXQ1'
    }
  },
  {
    path: '/administration/absences/types',
    name: 'absencetypes',
    component: () =>
      import(/* webpackChunkName: "absencetypes" */ '@/views/Absencetypes'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.absencetypes'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/mYqFwXQ1'
    }
  },
  {
    path: '/administration/absences/allocations',
    name: 'absenceallocations',
    component: () =>
      import(
        /* webpackChunkName: "absenceallocations" */ '@/views/Absenceallocations'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.absenceallocations'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/FHaHE1u2'
    }
  },
  {
    path: '/administration/timetracking',
    name: 'administration-timetracking',
    component: () =>
      import(
        /* webpackChunkName: "timetracking" */ '@/views/AdministrationTimetracking'
      ),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.projects'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/xbcgjcYE'
    }
  },
  {
    path: '/administration/timetracking/projects',
    name: 'projects',
    component: () =>
      import(/* webpackChunkName: "projects" */ '@/views/Projects'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.projects'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/bCN9DU3x'
    }
  },
  {
    path: '/administration/timetracking/activities',
    name: 'activities',
    component: () =>
      import(/* webpackChunkName: "activities" */ '@/views/Activities'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.activities'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/w1N0aouR'
    }
  },
  {
    path: '/administration/timetracking/tags',
    name: 'tags',
    component: () => import(/* webpackChunkName: "tags" */ '@/views/Tags'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.tags'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/tj0pZN2y'
    }
  },
  {
    path: '/administration/shifts',
    name: 'shifts',
    component: () => import(/* webpackChunkName: "shifts" */ '@/views/Shifts'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.shifts'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/Vxfjq8UP'
    }
  },
  {
    path: '/administration/integrations',
    name: 'integrations',
    component: () =>
      import(/* webpackChunkName: "integrations" */ '@/views/Integrations'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR', 'STAFF'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.integrations'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/fLf2o2HQ'
    }
  },
  {
    path: '/administration/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '@/views/Privacy'),
    meta: {
      requiresAuth: true,
      requiresRoles: ['SUPER_ADMINISTRATOR', 'ADMINISTRATOR'],
      layout: {
        fillHeight: false
      },
      title: i18n.t('navigation.privacy'),
      knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/10j6gjyP'
    }
  },

  /**
   * Public Pages
   */
  {
    path: '/application',
    name: 'application',
    component: () =>
      import(/* webpackChunkName: "application" */ '@/views/Application.vue'),
    props: (route) => ({ joboffer: route.query.joboffer }),
    meta: {
      requiresAuth: false,
      requiresUnauth: true,
      layout: {
        fillHeight: false,
        hideNavigation: true
      },
      title: i18n.t('application.title')
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '@/views/Jobs.vue'),
    props: (route) => ({ joboffer: route.query.joboffer }),
    meta: {
      requiresAuth: false,
      requiresUnauth: true,
      layout: {
        fillHeight: false,
        hideNavigation: true
      },
      title: i18n.t('jobs.title')
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () =>
      import(
        /* webpackChunkName: "privacypolicy" */ '@/views/Privacypolicy.vue'
      ),
    meta: {
      requiresAuth: false,
      requiresUnauth: false,
      layout: {
        fillHeight: false,
        hideNavigation: false
      },
      title: i18n.t('privacypolicy.title')
    }
  },

  // catch all
  {
    path: '*',
    redirect: { name: 'dashboard' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // wait till everything is loaded
  bootstrap
    .then(() => {
      const requiresUnauth = to.meta.requiresUnauth || false
      const userSignedIn = store.getters['auth/signedIn']

      if (requiresUnauth && userSignedIn) {
        Vue.toasted.error(i18n.t('messages.auth.requiredUnauth'), {
          duration: '10000',
          keepOnHover: true
        })

        next({ name: 'dashboard' })
      } else if (isAllowed(to)) {
        next()
      } else {
        next({
          name: 'login',
          params: { client: store.state.client || 'portal' },
          query: { redirect: to.fullPath }
        })
      }
    })
    .catch(() => {
      // next({ name: 'login' })
    })
})

function isAllowed(to) {
  const requiresAuth = to.meta.requiresAuth || false
  const requiresRoles = to.meta.requiresRoles || []
  const requiresPermissions = to.meta.requiresPermissions || []

  const userSignedIn = store.getters['auth/signedIn']
  const userRoles = store.getters['auth/roles']
  const userPermissions = store.getters['auth/permissions']

  if (requiresAuth) {
    if (!userSignedIn) {
      // route needs user to be authorized, but the user isn't authorized, so we will disallow the user
      return false
    }
  }

  if (requiresRoles.length > 0) {
    if (!userRoles.some((role) => requiresRoles.includes(role))) {
      // route needs user to have one role, but the user doesn't have it, so we will disallow the user
      return false
    }
  }

  if (requiresPermissions.length > 0) {
    if (
      !userPermissions.some((permission) =>
        requiresPermissions.includes(permission)
      )
    ) {
      // route needs user to have one permission, but the user doesn't have it, so we will disallow the user
      return false
    }
  }

  // Every check was ok, so we will allow the user
  return true
}

export default router
