import Vue from 'vue'
import moment from 'moment'
import DateUtils from '@/utils/date.js'

// month
Vue.filter('moment', function (value, format, originalFormat) {
  if (!value || !format) return ''

  return moment(value, originalFormat).format(format)
})

Vue.filter('date', function (value) {
  if (!value) return ''

  const filter = Vue.filter('moment')
  return filter(value, 'DD.MM.YYYY')
})

Vue.filter('time', function (value) {
  if (!value) return ''

  const filter = Vue.filter('moment')
  return filter(value, 'HH:mm') + ' Uhr'
})

Vue.filter('dateAndTime', function (value) {
  if (!value) return ''

  const filterDate = Vue.filter('date')
  const filterTime = Vue.filter('time')

  return filterDate(value) + ' - ' + filterTime(value)
})

Vue.filter('daterange', function ({ start, end } = {}) {
  if (!start || !end) return ''

  const filterDate = Vue.filter('date')

  return filterDate(start) + ' - ' + filterDate(end)
})

// date
Vue.filter('dateMonthAsText', function (value) {
  if (!value) return ''

  return value.toLocaleString('default', { month: 'long' })
})

Vue.filter('durationAsText', function (value) {
  if (!value) return ''

  return DateUtils.getDurationAsString(Math.abs(value))
})
